<template>
  <v-container fluid class="fill-height px-8">
    <v-row>
      <v-col>
        <h1 v-if="operator">Verwaltung {{operator.name}}</h1>
        <p>Angemeldet als: <strong>{{ $store.state.auth.user }}</strong></p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <operator-edit :operator="operator" @updateOperator="getOperator"></operator-edit>
      </v-col>
    </v-row>
    <v-row v-if="canAccessUsers">
      <v-col>
        <h4 class="mt-4">
          Benutzer Verwalten
        </h4>
      </v-col>
    </v-row>
    <v-row v-if="canAccessUsers">
      <v-col class="mt-0">
        <user-management v-if="operator" :operatorId="operator.id"></user-management>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <my-locations v-if="operator" :operatorId.sync="operator.id"></my-locations>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <my-events v-if="operator" :operatorId.sync="operator.id"></my-events>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <event-activities v-if="operator" :operator-id.sync="operator.id"></event-activities>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Events from '../components/Events'
import Locations from '../components/Locations'
import UserManagement from '../components/UserManagement'
import axios from 'axios'
import OperatorEdit from '../components/OperatorEdit'
import EventStats from "../components/EventStats.vue";
import EventActivities from "../components/EventActivities.vue";

export default {
  name: 'Dashboard',
  components: {
    'operator-edit': OperatorEdit,
    'my-events': Events,
    'my-locations': Locations,
    'user-management': UserManagement,
    'event-stats': EventStats,
    'event-activities': EventActivities
  },
  data () {
    return {
      showError: false,
      operator: null,
      canAccessUsers: false
    }
  },
  mounted () {
    this.getOperator()
    this.getUsers()
  },
  methods: {
    getOperator () {
      axios
        .get('/operators/' + this.$store.state.auth.operator)
        .then((response) => {
          this.operator = response.data
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            this.logout()
          }
        })
    },
    async logout () {
      await this.$store.dispatch('LogOut')
      this.$router.push('/login')
    },
    getUsers () {
      axios
        .get('/operators/' + this.$store.state.auth.operator + '/users')
        .then((response) => {
          if (response.status === 200) {
            this.canAccessUsers = true
          }
        })
    }
  }
}
</script>
