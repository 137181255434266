<template>
  <div>
    <h4 class="mt-3">
      Kurse verwalten

    </h4>
    <v-btn @click="createEvent()" color="primary" class="mt-4">Kurs erstellen</v-btn>
    <v-btn @click="openCsvImport()" color="" class="grey-light mt-4 ml-2">CSV Importieren</v-btn>

    <v-btn @click="exportEventsToCsv()" :loading="loading" color="" class="grey-light mt-4 ml-2">Kurse exportieren</v-btn>
    <a v-if="this.csvTemplateUrl" :href="csvTemplateUrl" :download="csvTemplateName"><v-btn @click="" color="" class="grey-light mt-4 ml-2">CSV Vorlage herunterladen</v-btn></a>

    <v-data-table
      :items="eventList"
      :headers="eventTable.headers"
      :items-per-page="10"
      :search="search"
      :loading="loading"
      class="elevation-1 mt-6">
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Kurse durchsuchen"
          class="mx-4 pt-6"
        ></v-text-field>
      </template>
      <template v-slot:item="props" v-slot:body.append>
        <tr @contextmenu="rightClick($event, props.item)">
          <td nowrap="true">{{ props.item.title }}</td>
          <td nowrap="true">{{ props.item.operatorReference }}</td>
          <td nowrap="true"><span v-if="props.item.startDate && props.item.startDate !== '0000-00-00'">{{ DateToLocaleDE(props.item.startDate) }}</span></td>
          <td nowrap="true"><span v-if="props.item.endDate && props.item.endDate !== '0000-00-00'">{{ DateToLocaleDE(props.item.endDate) }}</span></td>
          <td nowrap="true" v-if="props.item.courseLevel || props.item.courseLevel === 0">{{ courseLevelStr(props.item.courseLevel) }}<span v-if="props.item.subLevel">{{ subLevelStr(props.item.subLevel)}}</span></td>
          <td v-else></td>
          <td nowrap=""><span v-for="w in props.item.EventWindows" :key="w.weekday">{{ shortDayStr(w.weekday) }} ({{ w.timeStart }}-{{ w.timeEnd }})<br> </span></td>
          <td nowrap="true">CHF {{ props.item.totalCosts }}</td>
          <td nowrap="true">{{ props.item.lessonCount }}</td>
          <td nowrap="true">{{ props.item.lessonsPerWeek }}</td>
          <td nowrap="true">{{ props.item.EventLocation.address }}</td>
          <td nowrap="true"><v-chip v-for="e in props.item.EventCategories" :key="e.id" label small class="mr-2">{{ e.name }}</v-chip></td>
          <td nowrap="true"><a :href="generateUrl(props.item.externalUrl)" target="_blank">{{ props.item.externalUrl }}</a></td>
          <td nowrap="true">
            <v-simple-checkbox
              class="active-checkbox"
              v-model="props.item.active"
              @click="toogleEventActive(props.item)"
              disabled
            ></v-simple-checkbox>
          </td>
          <td nowrap="true">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon small @click="editEvent(props.item)" class="mr-2" v-on="on">mdi-pencil</v-icon>
              </template>
              <span>Kurs bearbeiten</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon small @click="confirmDeleteEvent(props.item)" class="mr-2" v-on="on">mdi-delete</v-icon>
              </template>
              <span>Kurs löschen</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon small @click="duplicateEvent(props.item)" v-on="on">mdi-content-copy</v-icon>
              </template>
              <span>Kurs duplizieren</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div v-if="eventActions.showEventDialog">
      <v-dialog v-model="eventActions.showEventDialog"
                persistent
                max-width="960px">
        <event-dialog :operatorId="operatorId"
                      :editExistingEvent="editExistingEvent"
                      :eventItemId="eventItemId"
                      @closeDialog=" eventActions.showEventDialog = false"
                      @getEvents="getEvents">
        </event-dialog>
      </v-dialog>
    </div>

    <div v-if="eventActions.showCsvDialog">
      <v-dialog v-model="eventActions.showCsvDialog"
                persistent
                max-width="1600px">
        <csv-import :operatorId="operatorId"
                      @closeDialog=" eventActions.showCsvDialog = false"
                      @getEvents="getEvents">
        </csv-import>
      </v-dialog>
    </div>

    <v-dialog v-model="eventActions.confirmDeleteEvent" max-width="400">
      <v-card>
        <v-card-title class="text-h6">Diesen Kurs entfernen?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="eventActions.confirmDeleteEvent = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" :loading="loading" text @click="deleteEvent">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-menu
      v-model="context.show"
      :position-x="context.x"
      :position-y="context.y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="context.show = false; editEvent(context.item)"
        >
          <v-list-item-title >Kurs bearbeiten</v-list-item-title>
        </v-list-item>
        <v-list-item @click="context.show = false; confirmDeleteEvent(context.item)">
          <v-list-item-title >Kurs löschen</v-list-item-title>
        </v-list-item>
        <v-list-item @click="context.show = false; duplicateEvent(context.item)">
          <v-list-item-title >Kurs duplizieren</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import axios from 'axios'
import {showSnack} from '../globalActions'
import moment from 'moment'
import EventDialog from './EventDialog'
import CsvImport from './CsvImport'

export default {
  name: 'Events',
  components: {
    'event-dialog': EventDialog,
    'csv-import': CsvImport
  },
  props: ['operatorId'],
  data () {
    return {
      loading: false,
      eventActions: {
        showEventDialog: false,
        confirmDeleteEvent: false,
        showCsvDialog: false
      },
      operator: {
        id: this.operatorId
      },
      editedEvent: null,
      editExistingEvent: false,
      eventItemId: null,
      context: {
        show: false,
        x: 0,
        y: 0,
        item: null
      },
      search: '',
      eventTable: {
        headers: [
          {text: 'Kurstitel', align: 'start', value: 'title'},
          {text: 'Referenz / Nr.', value: 'operatorReference'},
          {text: 'Kursstart', value: 'startDate'},
          {text: 'Kursende', value: 'endDate'},
          {text: 'Niveau', value: 'courseLevel'},
          {text: 'Zeiten', value: 'EventWindows'},
          {text: 'Gesamtkosten', value: 'totalCosts'},
          {text: 'Lektionen Total', value: 'lessonCount'},
          {text: 'Lektionen pro Woche', value: 'lessonsPerWeek'},
          {text: 'Kursort', value: 'EventLocation'},
          {text: 'Tags', value: 'EventCategories'},
          {text: 'URL', value: 'externalUrl'},
          {text: 'Aktiv', value: 'active'},
          {text: 'Aktionen', value: 'actions'}
        ]
      },
      courseLevels: [
        {value: 0, text: 'A0'},
        {value: 1, text: 'A1'},
        {value: 2, text: 'A2'},
        {value: 3, text: 'B1'},
        {value: 4, text: 'B2'},
        {value: 5, text: 'C1'},
        {value: 6, text: 'C2'}
      ],
      eventList: [],
      csvTemplateUrl: '',
      csvTemplateName: ''
    }
  },
  created () {
  },
  mounted () {
    this.getEvents()
    this.getLocations()

    this.getCsvTemplate()
  },
  watch: {
    operatorId: function () {
      this.getEvents()
      this.getLocations()
    }
  },
  methods: {
    getEvents () {
      this.loading = true
      axios
        .get('/operators/' + this.operatorId + '/events')
        .then((response) => {
          this.eventList = response.data
          this.loading = false
        })
    },
    getLocations () {
      axios
        .get('/operators/' + this.operatorId + '/locations')
        .then((response) => {
          this.locationList = response.data
        })
    },
    rightClick(event, item) {
      event.preventDefault();
      this.context.show = false
      this.context.x = event.clientX
      this.context.y = event.clientY
      this.context.item = item
      this.$nextTick(() => {
        this.context.show = true
      })
    },
    toogleEventActive (event) {
      axios
        .patch('/operators/' + this.operatorId + '/events/' + event.id + '/toggleActive')
        .then((response) => {
          if (response.status === 200) {
            showSnack({ message: 'Kurs wurde aktualisiert', color: 'success' })
            this.getEvents()
          } else {
            showSnack({message: 'Kurs konnte nicht aktualisert werden'})
          }
        })
    },
    courseLevelStr (level) {
      const levels = level.toString().split(',').map(Number)
      let strLevel = this.courseLevels[levels[0]].text
      if (levels.length > 1) {
        levels.forEach((l, i) => {
          if (i > 0) strLevel = strLevel + ',' + this.courseLevels[l].text
        })
      }

      return strLevel
    },
    subLevelStr (subLevel) {
      if (subLevel === null || subLevel === '0') {
        return ''
      }



      const levels = subLevel.toString().split(',')
      let levelStr = '-' + levels[0]
      if (levels.length > 1) {
        levels.forEach((l, i) => {
          if (i > 0) {
            l === '5' ? levelStr += ',+' : levelStr += ',' + l
          }
        })
      }

      return levelStr
    },
    confirmDeleteEvent (event) {
      this.editedEvent = event
      this.eventActions.confirmDeleteEvent = true
    },
    editEvent (event) {
      this.eventActions.showEventDialog = true
      this.editExistingEvent = true
      this.eventItemId = event.id
    },
    createEvent () {
      this.editExistingEvent = false
      this.eventItemId = 0
      this.eventActions.showEventDialog = true
    },
    openCsvImport () {
      this.eventActions.showCsvDialog = true
    },
    getCsvTemplate () {
      axios
        .get('/operators/' + this.operatorId + '/csvtemplate')
        .then((response) => {
          let csvHeaders = response.data


          let csvContent = "data:text/csv;charset=utf-8," + csvHeaders.join(';') + '\n'
          this.csvTemplateUrl = encodeURI(csvContent)
          this.csvTemplateName = 'deutschkurse_vorlage_import.csv'
        })
    },
    exportEventsToCsv () {
      this.loading = true
      axios
        .get('/operators/' + this.operatorId + '/eventsexport')
        .then((response) => {

          const csv = this.convertToCSV(response.data)
          const blob = new Blob([csv], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = url;
          a.download = 'Kursexport.csv';
          a.click();

          window.URL.revokeObjectURL(url);
          this.loading = false
        })
    },
    convertToCSV(data) {
      const header = Object.keys(data[0]).join(';') + '\n';
      const rows = data.map(obj => Object.values(obj).join(';')).join('\n');
      return header + rows;
    },
    deleteEvent () {
      this.loading = true
      axios.delete('/operators/' + this.operatorId + '/events/' + this.editedEvent.id)
        .then((response) => {
          if (response.status === 204) {
            showSnack({ message: 'Kurs wurde entfernt', color: 'success' })
            this.getEvents()
            this.eventActions.confirmDeleteEvent = false
          } else {
            showSnack({message: 'Kurs konnte nicht entfernt werden'})
            this.eventActions.confirmDeleteEvent = false
          }
        })
        .finally(() => this.loading = false)
    },
    duplicateEvent (event) {
      // open edit event dialog but save event as new when saving changes, this way a new event is created based on existing event values
      this.eventActions.showEventDialog = true
      this.editExistingEvent = false
      this.eventItemId = event.id
    },
    DateToLocaleDE (date) {
      // To have a leading day in Date, use small caps 'dd'. To get them in the right language, use .locale('lang')
      return moment(date).locale('de').format('dd, DD.MM.YYYY')
    },
    shortDayStr (weekday) {
      return ['', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'][weekday]
    },
    generateUrl (url) {
      if (typeof url === 'string') {
        if (url.indexOf('http') < 0) {
          url = 'https://' + url
        }
        return url
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped>
  .active-checkbox {
    cursor: pointer;
  }
</style>
