<template>
  <div>
    <h4 class="mt-3">
      Aktivitäten
    </h4>

    <v-data-table
      :items="eventList"
      :headers="eventTable.headers"
      :items-per-page="10"
      :search="search"
      :loading="loading"
      class="elevation-1 mt-6">
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Kurse durchsuchen"
          class="mx-4 pt-6"
        ></v-text-field>
      </template>
      <template v-slot:item="props" v-slot:body.append>
        <tr>
          <td nowrap="true">{{ new Date(props.item.createdAt).toLocaleTimeString('de-CH', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'}) }}</td>
          <td nowrap="true">{{ props.item.activity_text }}</td>
          <td nowrap="true">{{ props.item.changed_by }}</td>
          <td nowrap="true">{{ props.item.Event.title }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import axios from 'axios'
import {showSnack} from '../globalActions'
import moment from 'moment'
import EventDialog from './EventDialog'
import CsvImport from './CsvImport'

export default {
  name: 'EventActivities',
  components: {
  },
  props: ['operatorId'],
  data () {
    return {
      eventActions: {

      },
      operator: {
        id: this.operatorId
      },
      loading: false,
      eventItemId: null,
      context: {
        show: false,
        x: 0,
        y: 0,
        item: null
      },
      search: '',
      eventTable: {
        headers: [
          {text: 'Datum', align: 'start', value: 'createdAt'},
          {text: 'Aktivität', align: 'start', value: 'activity_text'},
          {text: 'Name', value: 'changed_by'},
          {text: 'Kurs', value: 'identifier'}
        ]
      },
      eventList: [],
    }
  },
  created () {
  },
  mounted () {
    this.getEvents()
  },
  watch: {
    operatorId: function () {
      this.getEvents()
    }
  },
  methods: {
    getEvents () {
      this.loading = true
      axios
        .get('/operators/' + this.operatorId + '/activities')
        .then((response) => {
          this.eventList = response.data
        })
        .finally(() => this.loading = false)
    },
    DateToLocaleDE (date) {
      // To have a leading day in Date, use small caps 'dd'. To get them in the right language, use .locale('lang')
      return moment(date).locale('de').format('dd, DD.MM.YYYY')
    },
    generateUrl (url) {
      if (typeof url === 'string') {
        if (url.indexOf('http') < 0) {
          url = 'https://' + url
        }
        return url
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped>
  .active-checkbox {
    cursor: pointer;
  }
</style>
