<template>
  <div>
    <h4 class="mt-3">
      Kursorte verwalten

    </h4>
    <v-btn @click="resetLocationForm(); dialog.createLocation = true" color="primary" class="mt-4">Kursort erstellen</v-btn>

    <v-data-table
      :items="locationList"
      :items-per-page="10"
      :loading="loading"
      class="elevation-1 mt-6">
      <template v-slot:header="">
        <thead class="v-data-table-header">
        <tr>
          <th class="text-start">ID</th>
          <th class="text-start">Name</th>
          <th class="text-start">Adresse</th>
          <th class="text-start">Kontakt</th>
          <th class="text-start">Telefon</th>
          <th class="text-start">Email</th>
          <th class="text-start">Aktionen</th>
        </tr>

        </thead>
      </template>
      <template v-slot:item="props">
        <tr v-if="!props.item.isOnline" @contextmenu="rightClick($event, props.item)">
          <td>{{ props.item.id }}</td>
          <td nowrap="true">{{ props.item.name }}</td>
          <td nowrap="true">{{ props.item.address }}</td>
          <td nowrap="true">{{ props.item.contact }}</td>
          <td nowrap="true">{{ props.item.phone }}</td>
          <td nowrap="true">{{ props.item.email }}</td>
          <td nowrap="true">
            <v-icon small @click="editLocation(props.item)" class="mr-2">mdi-pencil</v-icon>
            <v-icon small @click="confirmDeleteLocation(props.item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog.createLocation"
              persistent
              max-width="960px">
      <v-card>
        <v-card-title>
          <span class="headline">Kursort erstellen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <validation-observer
              ref="locationFormObserver"
              v-slot="{ }"
            >
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="form.createLocation.name"
                  label="Name/Titel"
                  hint=""
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <validation-provider
                  v-slot="{ errors  }"
                  name="Street"
                  rules="required"
                >
                <v-text-field
                  v-model="form.createLocation.street"
                  label="Strasse"
                  :error-messages="errors"
                  required
                ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
             <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors  }"
                  name="Zip"
                  rules="required"
                >
                <v-text-field
                  v-model="form.createLocation.zip"
                  label="PLZ"
                  :error-messages="errors"
                ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors  }"
                  name="City"
                  rules="required"
                >
                <v-text-field
                  v-model="form.createLocation.city"
                  label="Ort"
                  :error-messages="errors"
                ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
             <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="form.createLocation.contact"
                  label="Kontakt"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="form.createLocation.phone"
                  label="Kontakt Telefon"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <validation-provider
                  v-slot="{ errors  }"
                  name="Email"
                  rules="email"
                >
                <v-text-field
                  v-model="form.createLocation.email"
                  label="Kontakt Email Adresse"
                  :error-messages="errors"
                ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            </validation-observer>

            <v-alert type="error" v-model="locationError">
              Zur eingegebenen Adresse kann kein Ort auf der Google Maps Karte gefunden werden.
              Sie können den Kursort trotzdem erstellen, er wird dann aber nicht auf der Karte angezeigt. Alternativ geben Sie eine andere Adresse ein
              <v-btn class="d-block mt-2" @click="overwriteLocation(saveLocation)">Kursort trotzdem erstellen</v-btn>
            </v-alert>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog.createLocation = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="saving"
            @click="getAddressCoords(saveLocation)"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.editLocation"
              persistent
              max-width="960px">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Kursort bearbeiten</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <validation-observer
              ref="locationEditFormObserver"
              v-slot="{ }"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="form.createLocation.name"
                    label="Name/Titel"
                    hint=""
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <validation-provider
                    v-slot="{ errors  }"
                    name="Street"
                    rules="required"
                  >
                    <v-text-field
                      v-model="form.createLocation.street"
                      label="Strasse"
                      :error-messages="errors"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors  }"
                    name="Zip"
                    rules="required"
                  >
                    <v-text-field
                      v-model="form.createLocation.zip"
                      label="PLZ"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors  }"
                    name="City"
                    rules="required"
                  >
                    <v-text-field
                      v-model="form.createLocation.city"
                      label="Ort"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="form.createLocation.contact"
                    label="Kontakt"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="form.createLocation.phone"
                    label="Kontakt Telefon"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <validation-provider
                    v-slot="{ errors  }"
                    name="Email"
                    rules="email"
                  >
                    <v-text-field
                      v-model="form.createLocation.email"
                      label="Kontakt Email Adresse"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </validation-observer>
            <v-alert type="error" v-model="locationError">
              Zur eingegebenen Adresse kann kein Ort auf der Google Maps Karte gefunden werden.
              Sie können den Kursort trotzdem erstellen, er wird dann aber nicht auf der Karte angezeigt. Alternativ geben Sie eine andere Adresse ein
              <v-btn class="d-block mt-2" @click="overwriteLocation(updateLocation)">Kursort trotzdem erstellen</v-btn>
            </v-alert>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog.editLocation = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="saving"
            @click="getAddressCoords(updateLocation)"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.confirmDeleteLocation" max-width="400">
      <v-card>
        <v-card-title class="text-h6">Diesen Kursort entfernen?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog.confirmDeleteLocation = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" :loading="loading" text @click="deleteLocation">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-menu
      v-model="context.show"
      :position-x="context.x"
      :position-y="context.y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="context.show = false; editLocation(context.item)"
        >
          <v-list-item-title >Standort bearbeiten</v-list-item-title>
        </v-list-item>
        <v-list-item @click="context.show = false; confirmDeleteLocation(context.item)">
          <v-list-item-title >Standort löschen</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import axios from 'axios'
import { showSnack } from '../globalActions'
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {email, regex, required} from "vee-validate/dist/rules";
import {Loader} from "@googlemaps/js-api-loader";

extend('required', {
  ...required,
  message: 'Dies ist ein Pflichtfeld'
})

extend('email', {
  ...email,
  message: 'Es muss eine Email Adresse eingegeben werden'
})

extend('regex', {
  ...regex,
  message: 'Die Eingabe entspricht nicht dem erwarteten Format'
})

extend('confirmed', {
  ...regex,
  message: 'Das eingegebene Passwort stimmt nicht überein'
})

export default {
  name: 'Locations',
  props: ['operatorId'],
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      dialog: {
        createLocation: false,
        editLocation: false,
        confirmDeleteLocation: false
      },
      saving: false,
      loading: false,
      form: {
        createLocation: {
          street: '',
          city: '',
          zip: '',
          contact: '',
          phone: '',
          email: '',
          lat: '',
          lon: ''
        }
      },
      context: {
        show: false,
        x: 0,
        y: 0,
        item: null
      },
      locationError: false,
      editedLocation: null,
      locationList: [],
      google: null,
      geoCoder: null
    }
  },
  created () {
    this.initGeocoder()
  },
  mounted () {
    this.getLocations()
  },
  watch: {
    operatorId: function () {
      this.getLocations()
    }
  },
  methods: {
    getLocations () {
      this.loading = true
      axios
        .get('/operators/' + this.operatorId + '/locations')
        .then((response) => {
          this.locationList = response.data
        })
        .finally(() => {
          this.loading = false
          this.saving = false
        })
    },
    rightClick(event, item) {
      event.preventDefault();
      this.context.show = false
      this.context.x = event.clientX
      this.context.y = event.clientY
      this.context.item = item
      this.$nextTick(() => {
        this.context.show = true
      })
    },
    initGeocoder () {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_KEY,
        version: 'weekly',
        libraries: ['geocoder', 'places']
      })

      loader
        .load()
        .then((google) => {
          this.google = google
          this.geocoder = new google.maps.Geocoder()
        })
        .catch(e => {
          console.log(e)
        })
    },
    confirmDeleteLocation (location) {
      this.editedLocation = location
      this.dialog.confirmDeleteLocation = true
    },
    deleteLocation () {
      this.loading = true
      axios.delete('/operators/' + this.operatorId + '/locations/' + this.editedLocation.id)
        .then((response) => {
          if (response.status === 200) {
            showSnack({ message: 'Kursort wurde entfernt', color: 'success' })
            this.getLocations()
            this.dialog.confirmDeleteLocation = false
          } else {
            showSnack({ message: 'Kursort konnte nicht entfernt werden, stellen Sie sicher, dass keine Kurse an diesem Ort stattfinden.' })
            this.dialog.confirmDeleteLocation = false
          }
        })
        .catch((response) => {
          showSnack({ message: 'Kursort konnte nicht entfernt werden, stellen Sie sicher, dass keine Kurse an diesem Ort stattfinden.' })
          this.dialog.confirmDeleteLocation = false
        }).finally(() => this.loading = false)
    },
    editLocation (location) {
      this.locationError = false
      this.editedLocation = location
      this.form.createLocation = this.editedLocation
      this.dialog.editLocation = true
    },
    updateLocation () {
      this.$refs.locationEditFormObserver.validate()
        .then((valid) => {
          if (valid) {
            axios.patch('/operators/' + this.operatorId + '/locations/' + this.editedLocation.id, this.form.createLocation)
              .then((response) => {
                if (response.status === 200) {
                  showSnack({message: 'Kursort wurde aktualisiert', color: 'success'})
                  this.getLocations()
                  this.resetLocationForm()
                  this.dialog.editLocation = false
                } else {
                  showSnack({message: 'Kursort konnte nicht aktualisiert werden'})
                }
              })
              .finally()
          } else {
            showSnack({message: 'Das Formular ist nicht korrekt ausgefüllt'})
          }
        })
    },
    saveLocation () {
      this.$refs.locationFormObserver.validate()
        .then((valid) => {
          if (valid) {
            this.saving = true
            axios.post('/operators/' + this.operatorId + '/locations', this.form.createLocation)
              .then((response) => {
                if (response.status === 204) {
                  showSnack({message: 'Kursort wurde erstellt', color: 'success'})
                  this.locationError = false
                  this.getLocations()
                  this.dialog.createLocation = false
                } else {
                  showSnack({message: 'Kursort konnte nicht erstellt werden'})
                }
              })
              .finally(() => this.saving = false)
          } else {
            showSnack({message: 'Das Formular ist nicht korrekt ausgefüllt'})
            this.saving = false
          }
        })
    },
    getAddressCoords (callback) {
      this.saving = true
      // get coordinates with gmaps geocoder to save lat/lon with location
      const address = this.form.createLocation.street + ', ' + this.form.createLocation.zip + ' ' + this.form.createLocation.city
      this.geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location
          this.form.createLocation.lat = location.lat()
          this.form.createLocation.lon = location.lng()

          callback()
        } else {
          this.locationError = true
          console.log('Geocode was not successful for the following reason: ' + status)
          this.saving = false
        }
      })
    },
    overwriteLocation (callback) {
      this.form.createLocation.lat = ''
      this.form.createLocation.lon = ''
      callback()
    },
    resetLocationForm () {
      this.locationError = false
      this.form.createLocation = {
        street: '',
        city: '',
        zip: '',
        contact: '',
        phone: '',
        email: '',
        lat: '',
        lon: ''
      }
    }
  },
  computed: {
  }
}
</script>
