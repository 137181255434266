<template>
  <div>
    <v-btn @click="editOperator()" color="secondary" class="mt-4">Eigene Informationen anpassen</v-btn>
    <v-btn @click="dialog.logoDialog = true" color="secondary" class="mt-4 ml-3">Logo hochladen</v-btn>

    <v-dialog v-model="dialog.editOperator"
              persistent
              max-width="960px">
      <validation-observer
        ref="operatorEditForm"
        v-slot="{ }"
      >
      <v-card :loading="loading">
        <v-card-title>
          <v-container>
            <span class="headline">Kursanbieter bearbeiten</span>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="WebsiteUrl"
                  rules=""
                >
                  <v-text-field
                    v-model="form.editOperator.websiteUrl"
                    label="Webseite URL"
                    :error-messages="errors"
                    persistent-hint
                    hint="Die URL der Webseite wird bei der Kursübersicht als Link angezeigt."
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Contact"
                  rules=""
                >
                  <v-text-field
                    v-model="form.editOperator.contact"
                    label="Kontakt Name"
                    persistent-hint
                    hint="Der Kontakt des Anbieters wird bei der Kursübersicht als Info angezeigt"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Phone"
                  rules=""
                >
                  <v-text-field
                    v-model="form.editOperator.phone"
                    label="Kontakt Telefon"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules=""
                >
                  <v-text-field
                    v-model="form.editOperator.email"
                    label="Kontakt Email"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Zusätzliche Informationen"
                  rules=""
                >
                  <v-textarea
                    v-model="form.editOperator.additionalInfo"
                    label="Zusätzliche Informationen"
                    :error-messages="errors"
                    hint="maximal 500 Zeichen"
                  ></v-textarea>
                </validation-provider>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog.editOperator = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="saving"
            @click="saveOperator()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
      </validation-observer>
    </v-dialog>
    <v-dialog v-model="dialog.logoDialog"
              persistent
              max-width="600px">
        <v-card>
          <v-card-title>
            <v-container>
              <span class="headline">Logo hochladen</span>
            </v-container>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <p>
                    Hier kann das eigene Logo importiert werden. Falls schon ein Logo hochgeladen wurde, wird das vorhandene Logo ersetzt.
                  </p>
                </v-col>
              </v-row>
              <v-row v-if="operator.logo">
                <v-col>
                  <p>
                    Aktuelles Logo:
                  </p>
                  <v-img :src="logoBase + operator.logo" max-width="250"></v-img>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-file-input v-model="form.logoUpload.file" label="Logo auswählen" hint="Erlaubte Formate: jpg, png, svg" persistent-hint></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog.logoDialog = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="saving"
              @click="uploadLogo()"
            >
              Hochladen
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.confirmDeleteOperator" max-width="400">
      <v-card>
        <v-card-title class="text-h6">Diesen Anbieter entfernen?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog.confirmDeleteOperator = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="deleteOperator">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { showSnack } from '../globalActions'
import { required, email, regex, required_if, confirmed, max } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'Dies ist ein Pflichtfeld'
})

extend('email', {
  ...email,
  message: 'Es muss eine Email Adresse eingegeben werden'
})

extend('regex', {
  ...regex,
  message: 'Die Eingabe entspricht nicht dem erwarteten Format'
})

extend('confirmed', {
  ...regex,
  message: 'Das eingegebene Passwort stimmt nicht überein'
})

extend('max', {
  ...regex,
  message: 'Die Eingabe übersteigt die maximale erlaubte Anzahl von Zeichen'
})

export default {
  name: 'OperatorEdit',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: ['operator'],
  data () {
    return {
      dialog: {
        editOperator: false,
        logoDialog: false
      },
      logoBase: process.env.VUE_APP_AZURE_BLOB_BASE,
      loading: false,
      saving: false,
      form: {
        editOperator: {
          websiteUrl: '',
          contact: '',
          phone: '',
          email: '',
          additionalInfo: ''
        },
        logoUpload: {
          file: null
        }
      },
      editedOperator: null
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    editOperator () {
      this.form.editOperator.additionalInfo = this.operator.additionalInfo
      this.form.editOperator.phone = this.operator.phone
      this.form.editOperator.email = this.operator.email
      this.form.editOperator.contact = this.operator.contact
      this.form.editOperator.websiteUrl = this.operator.websiteUrl
      this.dialog.editOperator = true
    },
    saveOperator () {
      this.$refs.operatorEditForm.validate()
        .then((valid) => {
          if (valid) {
            this.saving = true
            axios.patch('/operators/' + this.operator.id, this.form.editOperator)
              .then((response) => {
                if (response.status === 200) {
                  showSnack({ message: 'Informationen wurde aktualisiert', color: 'success' })
                  this.dialog.editOperator = false
                  this.$emit('updateOperator')
                } else {
                  showSnack({ message: 'Fehler! Kursanbieter konnte nicht aktualisiert werden' })
                }
              })
              .catch(() => {
                showSnack({ message: 'Fehler! Kursanbieter konnte nicht aktualisiert werden' })
              })
              .finally(() => this.saving = false)
          } else {
            showSnack({message: 'Das Formular ist nicht korrekt ausgefüllt'})
          }
        })
    },
    uploadLogo () {
      if (this.form.logoUpload.file) {
        const formData = new FormData()

        formData.append('uploadedFile', this.form.logoUpload.file, this.form.logoUpload.file.name)
        this.saving = true
        axios.post('/operators/' + this.operator.id + '/logo', formData)
          .then((response) => {
            if (response.status === 200) {
              showSnack({ message: 'Logo wurde importiert', color: 'success' })
              this.dialog.logoDialog = false
              this.$emit('updateOperator')
            } else {
              showSnack({ message: 'Das Logo konnte nicht hochgeladen werden. Achten Sie darauf, dass nur png, jpeg und svg Dateien erlaubt sind.' })
            }
          })
          .catch(() => {
            showSnack({ message: 'Das Logo konnte nicht hochgeladen werden. Achten Sie darauf, dass nur png, jpeg und svg Dateien erlaubt sind.' })
          })
          .finally(() => this.saving = false)
      }
    }
  }
}
</script>
